<template>
 <PageHeading
  :button-title="$t('header.openTicket')"
  :has-meta-data="false"
  :has-action-button="true"
  :has-search="true"
  :has-permission="
   userPermissions.permissions.includes('ticketing.*') ||
   userPermissions.permissions.includes('ticketing.create')
  "
  :title="$t('ticketsOpen')"
  @open="this.openForm = !this.openForm"
  @update:search="$emit('update:search', $event)"
 />

 <div class="flex flex-col" v-if="this.openForm && this.ticketId === null">
  <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
   <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
    <div class="rounded-md overflow-hidden border border-gray-200">
     <OpenTicket
      v-on:closeForm="this.openForm = false"
      :checkStatus="checkStatus"
      @updateTicketDetail="(...args) => this.getTicketDetail(...args)"
      @activeBanner="$emit('activeBanner'), getTickets()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
 </div>
 <div v-if="tickets.length > 0">
  <div class="flex flex-col">
   <div class="-my-0 sm:-mx-6 lg:-mx-0">
    <div class="align-middle inline-block min-w-full lg:px-5 2xl:px-10">
     <TicketTable
      :tablePaginatedData="filteredTickets()"
      @getTickets="this.getTickets()"
      @sorting="sort($event)"
      @updateTicketDetail="(...args) => this.getTicketDetail(...args)"
      :statuses="status"
      @pushStatusSearch="this.pushSearchStatus($event)"
      :searchStatus="this.searchStatus"
      @clearStatusFilter="this.searchStatus.length = 0"
      :searchPriority="this.searchPriority"
      @pushPrioritySearch="this.pushSearchPriority($event)"
      @clearPriorityFilter="this.searchPriority.length = 0"
      @activeBanner="$emit('activeBanner'), getTickets()"
      @successMsg="$emit('successMsg', $event)"
      @success="$emit('success', $event)"
     />
    </div>
   </div>
  </div>
 </div>
 <div v-else>
  <EmptyState
   :title="$t('emptyStateTitle', { title: $t('ticketsOpen') })"
   :subtitle="$t('emptyStateSubtitle')"
  />
 </div>
 <!--  <div v-if="this.ticketId === null" class="flex justify-center">
  <div class="p-5">
   <div class="shadow overflow-hidden rounded-md">
    <TablePagination
     :tableData="filteredTickets()"
     @sort="sort($event)"
     @activeBanner="(activeBanner = true), getTickets(), (key = !key)"
     @successMsg="successMsg = $event"
     @success="isSuccess = $event"
     @updateTicketDetail="(...args) => this.getTicketDetail(...args)"
     :statuses="status"
     @pushStatusSearch="this.pushSearchStatus($event)"
     :searchStatus="this.searchStatus"
     @clearStatusFilter="this.searchStatus.length = 0"
     :searchPriority="this.searchPriority"
     @pushPrioritySearch="this.pushSearchPriority($event)"
     @clearPriorityFilter="this.searchPriority.length = 0"
     @getTickets="this.getTickets()"
    />
   </div>
  </div>
 </div> -->
</template>

<script>
import axios from "axios";

import EmptyState from "../components/EmptyState.vue";
import TicketTable from "../components/TicketTable.vue";
import TablePagination from "../components/TablePagination.vue";
import TicketDetail from "../components/TicketDetail";
import TicketDetailNew from "../components/TicketDetailNew";
import OpenTicket from "../components/OpenTicket.vue";
import SuccessBanner from "../components/SuccessBanner.vue";

import PageHeading from "../components/PageHeading.vue";

const account = localStorage.getItem("account");
const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));

const priorities = [
 { id: 1, name: "basse" },
 { id: 2, name: "normale" },
 { id: 3, name: "haute" },
 { id: 4, name: "critique" },
];

export default {
 name: "Tickets",
 props: ["colors", "closeTicketDetail", "search"],
 components: {
  EmptyState,
  TicketTable,
  TicketDetail,
  TicketDetailNew,
  TablePagination,
  OpenTicket,
  SuccessBanner,
  PageHeading,
 },
 data() {
  return {
   tickets: [],
   ticketId: null,
   statusName: null,
   currentSort: "id",
   currentSortDir: "desc",
   account,
   searchDate: "",
   searchStatus: [],
   searchPriority: [],
   searchDateUpdate: "",
   status: [],
   openForm: false,
   priorities,
   userPermissions,
  };
 },
 methods: {
  pushSearchStatus(status) {
   if (!this.searchStatus.includes(status)) {
    this.searchStatus.push(status);
   } else {
    let index = this.searchStatus.indexOf(status);
    if (index > -1) {
     this.searchStatus.splice(index, 1);
    }
   }
   this.$forceUpdate();
  },
  pushSearchPriority(priority) {
   console.log(priority);
   if (!this.searchPriority.includes(priority)) {
    this.searchPriority.push(priority);
   } else {
    let index = this.searchPriority.indexOf(priority);
    if (index > -1) {
     this.searchPriority.splice(index, 1);
    }
   }
   this.$forceUpdate();
  },
  checkPriority(id) {
   for (let index = 0; index < this.priorities.length; index++) {
    if (this.priorities[index].id == id) {
     return this.priorities[index].name;
    }
   }
  },
  async getTickets() {
   if (localStorage.getItem("token")) {
    this.$emit("setLoading", true);
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/tickets?customerAccount=${this.account}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );

     this.tickets = res.data;

     this.tickets = this.filterOpenTickets(res.data);
     this.$emit("tickesCount", this.tickets.length, this.filterClosedTickets(res.data).length);
    } catch (error) {
     this.errorHandling(error);
    } finally {
     this.$emit("setLoading", false);
    }
   }
  },
  orderArray(arr) {
   return arr.sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
   });
  },
  filterOpenTickets(data) {
   return data.filter((res) => {
    return res.open;
   });
  },
  filterClosedTickets(data) {
   return data.filter((res) => {
    return !res.open;
   });
  },

  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    dateCreated.getHours() +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2);

   return formatedDate;
  },

  checkStatus(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].name;
     }
    }
   }
  },
  checkStatusColor(id) {
   if (this.status) {
    for (let index = 0; index < this.status.length; index++) {
     if (this.status[index].id == id) {
      return this.status[index].color;
     }
    }
   }
  },
  sortedTickets() {
   return this.tickets.sort((a, b) => {
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
    return 0;
   });
  },
  numberIsEqual(numb) {
   return String(numb).includes(String(this.search));
  },
  filteredTickets() {
   return this.sortedTickets().filter((ticket) => {
    if (
     (this.searchStatus.length > 0 && !this.searchPriority.length > 0) ||
     (this.searchPriority.length > 0 && !this.searchStatus.length > 0)
    ) {
     let status = this.checkStatus(ticket.status_id);
     let priority = this.checkPriority(ticket.priority_id);
     if (this.search) {
      return (
       String(ticket.id).includes(this.search.trim()) ||
       ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
       this.$d(ticket.created_at, "longNumb").toLowerCase().includes(this.search.toLowerCase()) ||
       this.$d(ticket.updated_at, "longNumb").toLowerCase().includes(this.search.toLowerCase())
      );
     }

     return this.searchStatus.includes(status) || this.searchPriority.includes(priority);
    } else if (
     (this.searchStatus.length > 0 && this.searchPriority.length > 0) ||
     (this.searchPriority.length > 0 && this.searchStatus.length > 0)
    ) {
     let status = this.checkStatus(ticket.status_id);
     let priority = this.checkPriority(ticket.priority_id);
     if (this.search) {
      return (
       ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
       String(ticket.id).includes(this.search.trim()) ||
       this.$d(ticket.created_at, "longNumb").toLowerCase().includes(this.search.toLowerCase()) ||
       this.$d(ticket.updated_at, "longNumb").toLowerCase().includes(this.search.toLowerCase())
      );
     }
     return this.searchStatus.includes(status) && this.searchPriority.includes(priority);
    } else if (this.search) {
     return (
      ticket.subject.toLowerCase().includes(this.search.toLowerCase()) ||
      String(ticket.id).includes(this.search.trim()) ||
      this.$d(ticket.created_at, "longNumb").toLowerCase().includes(this.search.toLowerCase()) ||
      this.$d(ticket.updated_at, "longNumb").toLowerCase().includes(this.search.toLowerCase())
     );
    }
    return ticket;
   });
  },
  async getStatus() {
   this.$emit("setLoading", true);
   const res = await axios.get(`${this.$cookie.getCookie("API")}/api/v1/statuses`, {
    headers: {
     Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
   });
   this.status = res.data;
   //    this.$emit("setLoading", false);
  },
  getTicketDetail(...args) {
   this.ticketId = args[0];
   this.statusName = args[1];
   this.$router.push(`/ticket/${this.ticketId}`);
   this.$forceUpdate();
  },
 },
 mounted() {
  this.getTickets();
  this.getStatus();
 },
 watch: {
  closeTicketDetail: function (val) {
   this.ticketId = null;
  },
 },
};
</script>

<style></style>
